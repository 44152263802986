// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-posts-js": () => import("/opt/build/repo/src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-sermons-js": () => import("/opt/build/repo/src/templates/sermons.js" /* webpackChunkName: "component---src-templates-sermons-js" */),
  "component---src-templates-events-js": () => import("/opt/build/repo/src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-ministries-js": () => import("/opt/build/repo/src/templates/ministries.js" /* webpackChunkName: "component---src-templates-ministries-js" */),
  "component---src-pages-beliefs-js": () => import("/opt/build/repo/src/pages/beliefs.js" /* webpackChunkName: "component---src-pages-beliefs-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ministries-js": () => import("/opt/build/repo/src/pages/ministries.js" /* webpackChunkName: "component---src-pages-ministries-js" */),
  "component---src-pages-salvation-plan-js": () => import("/opt/build/repo/src/pages/salvation_plan.js" /* webpackChunkName: "component---src-pages-salvation-plan-js" */),
  "component---src-pages-sermons-js": () => import("/opt/build/repo/src/pages/sermons.js" /* webpackChunkName: "component---src-pages-sermons-js" */)
}

